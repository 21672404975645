import React, { useEffect, useState, useRef } from "react";
import { Table, Button, Input, Modal, notification } from "antd";
import { AudioOutlined, FilterOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { getAudioTemplates, deleteAudioTemplate } from "Requests/audio-library/index";
import AddAudioTemplateDrawer from "./AddTemplate";
import FileNameModal from "Components/Shared/FileNameModal/FileNameModal";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import { ExportButton } from "Pages/Setting/styles";
import { TableWrapper } from "Styles/global";
import NewPromotionIcon from "Assets/Icons/NewPromotionIcon";

const AudioLibrary = () => {
  const [audioTemplates, setAudioTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedAudio, setSelectedAudio] = useState(null);

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const [fileName, setFileName] = useState(`Audio_Library_${moment().format("YYYYMMDD_HHmmss")}`);
  const [isFileModalVisible, setIsFileModalVisible] = useState(false);

  const audioPlayerRef = useRef(null);

  useEffect(() => {
    fetchAudioTemplates();
  }, []);

  const fetchAudioTemplates = async () => {
    setLoading(true);
    try {
      const data = await getAudioTemplates();
      setAudioTemplates(data);
    } catch (error) {
      notification.error({ message: "Failed to fetch audio templates." });
    } finally {
      setLoading(false);
    }
  };

  const handleAddOrUpdateTemplate = (newTemplate) => {
    setAudioTemplates((prevTemplates) => {
      const existingIndex = prevTemplates.findIndex((item) => item.id === newTemplate.id);
      if (existingIndex > -1) {
        const updatedTemplates = [...prevTemplates];
        updatedTemplates[existingIndex] = newTemplate;
        return updatedTemplates;
      }
      return [newTemplate, ...prevTemplates];
    });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}>
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => {
      const recordValue = Array.isArray(dataIndex)
        ? dataIndex.reduce((prev, curr) => (prev && prev[curr] ? prev[curr] : null), record)
        : record[dataIndex];

      return recordValue?.toString().toLowerCase().includes(value.toLowerCase()) || false;
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ""} />
      ) : (
        text
      )
  });

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      render: (text, record) => (
        <span
          style={{ color: "#39B54A", cursor: "pointer" }}
          onClick={() => {
            setSelectedTemplate(record);
            setIsDrawerVisible(true);
          }}>
          {text}
        </span>
      )
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ...getColumnSearchProps("status")
    },
    {
      title: "Company",
      dataIndex: ["company", "company_name"],
      key: "company_name",
      ...getColumnSearchProps(["company", "company_name"]),
      render: (_, record) => (record.company ? record.company.company_name : "")
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      ...getColumnSearchProps("tags")
    },
    {
      title: "Preview",
      key: "preview",
      render: (_, record) => (
        <Button
          icon={<AudioOutlined />}
          onClick={() => {
            setSelectedAudio(record);
            setIsModalVisible(true);
          }}
        />
      )
    }
  ];

  const handleExport = () => {
    const exportData = audioTemplates.map((item) => ({
      Id: item.id,
      Name: item.name,
      Status: item.status,
      Company: item.company ? item.company.company_name : "N/A",
      Tags: item.tags
    }));

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = { Sheets: { Audio_Library: ws }, SheetNames: ["Audio_Library"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    });
    FileSaver.saveAs(dataBlob, `${fileName}.xlsx`);
    setIsFileModalVisible(false);
  };

  return (
    <TableWrapper>
      <FileNameModal
        fileName={fileName}
        setFileName={setFileName}
        open={isFileModalVisible}
        handleOk={handleExport}
        handleCancel={() => setIsFileModalVisible(false)}
      />
      <div style={{ textAlign: "right", marginBottom: "10px" }}>
        <ExportButton onClick={() => setIsFileModalVisible(true)}>Export Table</ExportButton>
      </div>
      {!isDrawerVisible && (
        <div
          className="new-promotion"
          onClick={() => {
            setSelectedTemplate(null);
            setIsDrawerVisible(true);
          }}>
          <NewPromotionIcon />
        </div>
      )}
      <Table
        dataSource={audioTemplates}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          defaultPageSize: 10
        }}
      />
      <AddAudioTemplateDrawer
        visible={isDrawerVisible}
        onClose={() => {
          setIsDrawerVisible(false);
          setSelectedTemplate(null);
        }}
        template={selectedTemplate}
        handleAddOrUpdateTemplate={handleAddOrUpdateTemplate}
      />
      <Modal
        open={isModalVisible}
        footer={null}
        onCancel={() => {
          setIsModalVisible(false);
          setSelectedAudio(null);
          if (audioPlayerRef.current) {
            audioPlayerRef.current.pause();
          }
        }}
        title="Preview">
        {selectedAudio && (
          <audio
            ref={audioPlayerRef}
            controls
            src={selectedAudio.template_url}
            style={{ width: "100%" }}
            onError={() => notification.error({ message: "Failed to load audio. Please try again later." })}>
            Your browser does not support the audio element.
          </audio>
        )}
      </Modal>
    </TableWrapper>
  );
};

export default AudioLibrary;
