import { doGet, doPost } from "Requests/requests";

export const getFonts = async (params = {}) => {
  try {
    const queryString = Object.entries(params)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    const endpoint = `/fonts?${queryString}`;
    return await doGet(endpoint);
  } catch (e) {
    throw e;
  }
};

export const addFontByUrl = async (data) => {
  try {
    const endpoint = "/add-font-url";
    return await doPost(endpoint, data);
  } catch (e) {
    throw e;
  }
};

export const addFontByFile = async (formData) => {
  try {
    const endpoint = "/add-font-file";
    const headers = {
      "Content-Type": "multipart/form-data"
    };
    return await doPost(endpoint, formData, headers);
  } catch (e) {
    throw e;
  }
};
