import React, { useEffect, useState, useRef } from "react";
import { Checkbox, notification, Row, Col, Modal, Button, Input, message } from "antd";
import { useSelector } from "react-redux";
import { ContextMenu, ContextMenuItem, ContextMenuTrigger } from "rctx-contextmenu";
import { CREATE_PROMOTION_ARCHIVE, CREATE_PROMOTION_RENAME, CREATE_PROMOTION_SUSPEND } from "constants/Templates";
import Loader from "Pages/Companies/Edit/Loader";
import { PromotionEditor } from "Pages/Promotions/CreatePromotion/utils/promotionEditor";
import { getAllVideoTemplates, updateVideoTemplate } from "Requests/video-template";
import { companyName } from "Redux/App";

const VideoThumbnail = React.memo(({ template, onClick }) => {
  const videoRef = useRef(null);

  const handleMouseEnter = async () => {
    if (videoRef.current) {
      try {
        await videoRef.current.play();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleMouseLeave = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        cursor: "pointer"
      }}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <video
        ref={videoRef}
        src={template?.preview_url ? template.preview_url : template.template_url}
        poster={template.thumbnail_url}
        muted
        playsInline
        controls={false}
        preload="metadata"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          borderRadius: "12px"
        }}
      />
    </div>
  );
});

export const TemplateTiles = () => {
  const selectedCompany = useSelector(companyName);
  const [loading, setLoading] = useState(false);
  const [showGeneric, setShowGeneric] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [templateToMutate, setTemplateToMutate] = useState(null);
  const [newTemplateName, setNewTemplateName] = useState("");
  const [refreshVideos, toggleRefreshVideos] = useState(false);
  const [updatingTemplate, setUpdatingTemplate] = useState(false);
  const [hoveredTextId, setHoveredTextId] = useState(null);

  const centeredFlex = {
    display: "flex",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center"
  };

  const handleRename = (template) => {
    setNewTemplateName(template.name);
    setTemplateToMutate(template);
    setRenameModalOpen(true);
  };

  const saveRename = async () => {
    if (!newTemplateName) {
      notification.error({ message: "New template name cannot be empty" });
      return;
    }
    try {
      setUpdatingTemplate(true);
      const payload = {
        id: templateToMutate.id,
        name: newTemplateName,
        status: templateToMutate.status,
        company: templateToMutate.company
      };
      await updateVideoTemplate(payload);
      toggleRefreshVideos((prev) => !prev);
      resetRenameModal();
    } catch (e) {
    } finally {
      setUpdatingTemplate(false);
    }
  };

  const resetRenameModal = () => {
    setTemplateToMutate(null);
    setRenameModalOpen(false);
    setNewTemplateName("");
  };

  const handleStatus = async (template, status) => {
    try {
      setLoading(true);
      const payload = {
        id: template.id,
        name: template.name,
        status: status,
        company: template.company
      };
      await updateVideoTemplate(payload);
      toggleRefreshVideos((prev) => !prev);
    } catch (e) {
      notification.error({ message: "Unable to update template" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getTemplates = async () => {
      if (!selectedCompany) return;
      try {
        setLoading(true);
        const response = await getAllVideoTemplates(selectedCompany, showGeneric);
        setTemplates(response);
      } catch (e) {
        notification.error({ message: "Error getting templates" });
      } finally {
        setLoading(false);
      }
    };
    getTemplates();
  }, [selectedCompany, showGeneric, refreshVideos]);

  if (selectedTemplate) return <PromotionEditor templateId={selectedTemplate.id} />;

  return (
    <div style={{ ...centeredFlex, padding: "20px" }}>
      <div style={{ backgroundColor: "white", height: "100%", width: "100%" }}>
        {loading ? (
          <div style={centeredFlex}>
            <Loader loading={true} />
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column", rowGap: "12px" }}>
            <div style={{ ...centeredFlex, justifyContent: "end", padding: "12px" }}>
              Include Generic Templates
              <Checkbox onChange={(e) => setShowGeneric(e.target.checked)} checked={showGeneric} />
            </div>
            <Modal
              title="Rename Template"
              open={renameModalOpen}
              onCancel={resetRenameModal}
              className="custom-modal-context-menu"
              footer={[
                <Button key="submit" type="primary" onClick={saveRename} loading={updatingTemplate}>
                  Save
                </Button>
              ]}>
              <h3>Template Name</h3>
              <Input value={newTemplateName} onChange={(e) => setNewTemplateName(e.target.value)} />
            </Modal>
            <Row gutter={16}>
              {templates.map((template) => (
                <Col key={template.id} span={6}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "20px",
                      rowGap: "8px"
                    }}>
                    <ContextMenuTrigger id={template.id}>
                      <VideoThumbnail template={template} onClick={() => setSelectedTemplate(template)} />
                    </ContextMenuTrigger>

                    <ContextMenu id={template.id}>
                      <ContextMenuItem onClick={() => handleRename(template)}>
                        {CREATE_PROMOTION_RENAME}
                      </ContextMenuItem>
                      <ContextMenuItem onClick={() => handleStatus(template, "Pending")}>
                        {CREATE_PROMOTION_SUSPEND}
                      </ContextMenuItem>
                      <ContextMenuItem onClick={() => handleStatus(template, "Archive")}>
                        {CREATE_PROMOTION_ARCHIVE}
                      </ContextMenuItem>
                    </ContextMenu>
                    <div
                      style={{
                        display: "inline-block",
                        overflow: hoveredTextId === template.id ? "visible" : "hidden",
                        textOverflow: hoveredTextId === template.id ? "unset" : "ellipsis",
                        whiteSpace: hoveredTextId === template.id ? "normal" : "nowrap",
                        width: "calc(95%)"
                      }}
                      onMouseEnter={() => setHoveredTextId(template.id)}
                      onMouseLeave={() => setHoveredTextId(null)}>
                      {`Name: ${template.name}`}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%"
                      }}>
                      <div>{`Duration: ${template.duration} secs`}</div>
                      {!template.company && <div>Generic</div>}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        )}
      </div>
    </div>
  );
};
