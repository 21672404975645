import React, { useEffect, useState } from "react";
import { Drawer, Form, Select, Input, Button, Row, Col, notification, Upload } from "antd";
import { DrawerTitle, MainTitle, CloseBtnWrapper, DrawerBodyPadding } from "Styles/global";
import CloseBtnIcon from "Assets/Icons/CloseBtn";
import UploadIcon from "Assets/Icons/UploadIcon";
import LoadingSpinner from "Components/Shared/LoadingSpinner";
import { createAudioTemplate, editAudioTemplate, getCompanies } from "Requests/audio-library/index";
import { useSelector } from "react-redux";
import { systemSettingsData } from "../../Redux/App/Reducers/System";

const AddAudioTemplateDrawer = ({ visible, onClose, template, handleAddOrUpdateTemplate }) => {
  const [form] = Form.useForm();
  const [companies, setCompanies] = useState({ data: [], loading: false });
  const [createTemplateLoading, setCreateTemplateLoading] = useState(false);
  const [file, setFile] = useState({ selectedFile: null, selectedFileList: [] });

  const fileSizeLimit = useSelector(systemSettingsData)?.promo_file_size;

  useEffect(() => {
    const fetchCompanies = async () => {
      setCompanies((prev) => ({ ...prev, loading: true }));
      try {
        const companiesData = await getCompanies();
        setCompanies({ data: companiesData, loading: false });
      } catch (error) {
        notification.error({
          message: error.message
        });
        setCompanies((prev) => ({ ...prev, loading: false }));
      }
    };

    fetchCompanies();

    if (template) {
      form.setFieldsValue({
        name: template.name,
        tags: template.tags,
        status: template.status,
        company_id: template?.company?.id ?? null
      });
    } else {
      form.resetFields();
    }
  }, [template, form]);

  const resetFields = () => {
    form.resetFields();
    setFile({ selectedFile: null, selectedFileList: [] });
  };

  const onFinish = async (values) => {
    if (!template && !file.selectedFile) {
      notification.error({
        message: "Please upload an audio file."
      });
      return;
    }

    if (!template && file?.selectedFile?.type !== "audio/mpeg")
      return notification.error({
        message: "Uploaded file format not supported"
      });

    setCreateTemplateLoading(true);

    try {
      if (template) {
        const updatedValues = { ...values, id: template.id, name: values.name || template.name };
        const updatedTemplate = await editAudioTemplate(updatedValues);
        notification.success({ message: "Audio template updated successfully." });

        if (handleAddOrUpdateTemplate) {
          handleAddOrUpdateTemplate(updatedTemplate);
        }
      } else {
        const formData = new FormData();
        formData.append("file", file.selectedFile);
        if (values.company_id) formData.append("company_id", values.company_id);
        if (values.tags) formData.append("tags", values.tags);
        if (values.status) formData.append("status", values.status);
        if (values.name) formData.append("name", values.name || file.selectedFile.name);

        const newTemplate = await createAudioTemplate(formData);
        notification.success({ message: "Audio template created successfully." });

        if (handleAddOrUpdateTemplate) {
          handleAddOrUpdateTemplate(newTemplate);
        }
      }
      resetFields();
      onClose();
    } catch (error) {
      notification.error({
        message: "An error occurred while processing the template."
      });
    } finally {
      setCreateTemplateLoading(false);
    }
  };

  const uploadProps = {
    multiple: false,
    accept: ".mp3",
    fileList: file.selectedFileList,
    maxCount: 1,
    onChange: (info) => {
      const selectedFile = info.fileList[0]?.originFileObj || null;
      setFile({ selectedFile, selectedFileList: info.fileList });
    },
    onRemove: () => {
      setFile({ selectedFile: null, selectedFileList: [] });
      return true;
    },
    beforeUpload: (file) => {
      const isSupportedFormat = file.type === "audio/mpeg";
      const fileSizeInMB = file.size / 1024 ** 2;

      if (!isSupportedFormat) {
        notification.error({ message: "Please upload an MP3 file." });
        return Upload.LIST_IGNORE;
      }

      if (fileSizeInMB > fileSizeLimit) {
        notification.error({ message: `File size exceeds ${fileSizeLimit} MB.` });
        return Upload.LIST_IGNORE;
      }

      return false;
    }
  };

  return (
    <Drawer headerStyle={{ display: "none" }} placement="right" closable={false} onClose={onClose} open={visible}>
      <DrawerTitle height={118}>
        <CloseBtnWrapper onClick={onClose}>
          <CloseBtnIcon />
        </CloseBtnWrapper>
        <MainTitle>
          <p className="main-title">{template ? "Edit Audio Template" : "Add New Audio Template"}</p>
        </MainTitle>
      </DrawerTitle>
      <DrawerBodyPadding>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item name="name" label="Name">
            <Input placeholder="Enter template name" />
          </Form.Item>
          <Form.Item name="tags" label="Tags">
            <Input placeholder="Enter tags" />
          </Form.Item>
          <Form.Item name="status" label="Status" rules={[{ required: true, message: "Please select status" }]}>
            <Select placeholder="Select status">
              <Select.Option value="Active">Active</Select.Option>
              <Select.Option value="Pending">Pending</Select.Option>
              <Select.Option value="Archive">Archive</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="company_id" label="Company">
            <Select placeholder="Select company" loading={companies.loading} allowClear={true}>
              {companies.data.map((company) => (
                <Select.Option key={company.id} value={company.id}>
                  {company.company_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {!template && (
            <Form.Item>
              <Upload.Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                  <UploadIcon />
                </p>
                <p className="ant-upload-text">Click or drag file to upload</p>
                <p className="ant-upload-hint">Supports a single file upload. Max size: {fileSizeLimit} MB.</p>
              </Upload.Dragger>
            </Form.Item>
          )}
          {createTemplateLoading && <LoadingSpinner />}
          <Row gutter={16} justify="end">
            <Col>
              <Button onClick={onClose}>Cancel</Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit" loading={createTemplateLoading}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </DrawerBodyPadding>
    </Drawer>
  );
};

export default AddAudioTemplateDrawer;
