import { Drawer, Form } from "antd";
import { Input, Select, Switch } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import debounce from "lodash/debounce";

import { DrawerTitle, MainTitle, CloseBtnWrapper } from "Styles/global";
import CloseBtnIcon from "Assets/Icons/CloseBtn";
import { useDispatch } from "react-redux";
import { EditCompany, addNewCompany, getVistarEnv } from "Redux/App/Actions/Companies";
import { EDIT_COMPANY } from "constants/Company";
import getRequiredMsg from "utils/formRequiredMsg";
import Submit from "./Edit/Submit";
import EditModal from "./Edit/Modal";
import Loader from "./Edit/Loader";
import { isHexCode } from "utils/isHexCode";
import { getFonts } from "Requests/fonts/index";
import { getAdvertiserList } from "Requests/advertiser/index";

const EditCompanyDrawer = (props) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fontLoading, setFontLoading] = useState(false);
  const [values, setValues] = useState({});
  const [vistarList, setVistarList] = useState([]);
  const [fonts, setFonts] = useState([]);
  const [advertisers, setAdvertisers] = useState([]);
  const dispatch = useDispatch();
  const { newCompany } = props;

  const fetchFonts = async (searchTerm = "", offset = 0, limit = 100) => {
    try {
      setFontLoading(true);
      const response = await getFonts({ search_term: searchTerm, offset, limit });
      setFonts(response);
    } catch (error) {
      console.error("Failed to fetch fonts", error);
    } finally {
      setFontLoading(false);
    }
  };

  const debouncedFetchFonts = useCallback(
    debounce((searchTerm) => {
      fetchFonts(searchTerm);
    }, 300),
    []
  );

  useEffect(() => {
    fetchFonts();
  }, []);

  const handleFontSearch = (value) => {
    debouncedFetchFonts(value);
  };

  useEffect(() => {
    const fetchadvertisers = async () => {
      try {
        const response = await getAdvertiserList();
        setAdvertisers(response);
      } catch (error) {
        console.error("Failed to fetch advertisers", error);
      }
    };
    fetchadvertisers();
  }, []);

  const handleAdvertiserChange = (value) => {
    const selectedadvertiser = advertisers.find((advertiser) => advertiser.id === value);
    if (selectedadvertiser) {
      form.setFieldsValue({ vistar_advertiser_id: selectedadvertiser.advertiser_id });
    } else {
      form.setFieldsValue({ vistar_advertiser_id: undefined });
    }
  };

  const onFinish = (values) => {
    const colorPalette = values?.color_palette ? values.color_palette.split(",").map((it) => it.trim()) : null;

    let fontFamily;
    if (Array.isArray(values.font_family)) {
      fontFamily = values.font_family.join(",");
    } else if (typeof values.font_family === "string") {
      fontFamily = values.font_family;
    } else {
      fontFamily = null;
    }

    const mutateValues = {
      ...values,
      color_palette: colorPalette,
      font_family: fontFamily,
      maintain_sites_flag: !!values?.maintain_sites_flag
    };

    setValues(values);

    const vistarEnvValue = vistarList.find((each) => each.id === values.vistar_env_id)?.vistar_env || null;

    if (newCompany) {
      dispatch(
        addNewCompany(
          {
            ...mutateValues,
            logo_url: values?.logo_url ?? "default"
          },
          setLoading,
          props,
          vistarEnvValue
        )
      );
    } else if (values.company_name !== props.companyData.data.company_name) {
      setIsModalOpen(true);
    } else {
      dispatch(EditCompany(mutateValues, setLoading, form, props, props.fetchCompanies, props.toggleFetchCompanies));
    }

    form.resetFields();
  };

  const fontOptions = fonts.map((font) => ({
    value: font.post_script_name,
    label: font.full_name
  }));

  useEffect(() => {
    if (props?.companyData && advertisers.length > 0) {
      const data = { ...props.companyData.data };
      const colorPalette = data.color_palette
        ? JSON.parse(data.color_palette).reduce((a, b) => {
            return `${a}${a ? ", " : ""}${b}`;
          }, "")
        : null;

      const fontFamily = data.font_family ? data.font_family.split(",") : [];

      const selectedadvertiser = advertisers.find(
        (advertiser) => advertiser.advertiser_id === data.vistar_advertiser_id
      );

      const formData = {
        ...data,
        color_palette: colorPalette,
        font_family: fontFamily,
        vistar_advertiser_name: selectedadvertiser ? selectedadvertiser.name : undefined,
        vistar_advertiser_id: selectedadvertiser ? selectedadvertiser.advertiser_id : undefined
      };
      form.setFieldsValue(formData);

      if (fontFamily.length > 0) {
        getFonts({ post_script_names: fontFamily.join(",") })
          .then((response) => {
            setFonts((prevFonts) => {
              const fontMap = new Map();
              [...response, ...prevFonts].forEach((font) => {
                fontMap.set(font.post_script_name, font);
              });
              return Array.from(fontMap.values());
            });
          })
          .catch((error) => {
            console.error("Failed to fetch selected fonts", error);
          });
      }
    }
  }, [props.companyData, advertisers]);

  const handleOk = () => {
    dispatch(EditCompany(values, setLoading, form, props, props.fetchCompanies, props.toggleFetchCompanies));
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Drawer
      headerStyle={{ display: "none" }}
      title="Edit Company"
      placement={"right"}
      closable={false}
      onClose={() => {
        props.setDrawerOpen(false);
        form.resetFields();
      }}
      open={props.drawerOpen.open}>
      <DrawerTitle height={118}>
        <CloseBtnWrapper onClick={() => props.setDrawerOpen(false)}>
          <CloseBtnIcon />
        </CloseBtnWrapper>
        <MainTitle>
          <p className="main-title">{props.drawerOpen.screen} Company</p>
        </MainTitle>
      </DrawerTitle>
      <Loader loading={loading} STYLE={EDIT_COMPANY.DRAWER_TITLE_STYLE} />
      <Form onFinish={onFinish} form={form} layout="vertical">
        {!newCompany && (
          <Form.Item name="id" label="ID">
            <Input disabled />
          </Form.Item>
        )}
        <Form.Item name="company_name" label="Company Name" rules={getRequiredMsg("Please enter company name")}>
          <Input />
        </Form.Item>
        <Form.Item name="coupon" label="Coupon" rules={getRequiredMsg("Please select status")}>
          <Select options={EDIT_COMPANY.COUPON_ARR} />
        </Form.Item>

        <Form.Item name="xibo_folder_id" label="Xibo Folder ID" rules={getRequiredMsg("Please enter Xibo Folder ID")}>
          <Input />
        </Form.Item>

        <Form.Item name="status" label="Status" rules={getRequiredMsg("Please select status")}>
          <Select options={EDIT_COMPANY.STATUS_ARR} />
        </Form.Item>

        <Form.Item
          name="create_promo_flag"
          label="Create Promotion Flag"
          rules={getRequiredMsg("Please select status")}>
          <Select options={EDIT_COMPANY.CREATE_PROMO_ARR} />
        </Form.Item>

        <Form.Item name="maintain_sites_flag" label="Maintain Sites" valuePropName="checked">
          <Switch />
        </Form.Item>

        <Form.Item name="vistar_env_id" label="Vistar Name">
          <Select allowClear>
            {vistarList.map((vistar) => (
              <Select.Option key={vistar?.id} value={vistar?.id}>
                {vistar.vistar_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="vistar_advertiser_name"
          label="Advertiser Name"
          rules={getRequiredMsg("Please select an advertiser")}>
          <Select
            showSearch
            placeholder="Select an advertiser"
            optionFilterProp="children"
            onChange={handleAdvertiserChange}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
            {advertisers.map((advertiser) => (
              <Select.Option key={advertiser.id} value={advertiser.id}>
                {advertiser.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="vistar_advertiser_id" label="Advertiser ID">
          <Input disabled />
        </Form.Item>

        <Form.Item name="ad_server_tz" label="Timezone">
          <Select allowClear>
            {props.timezones.map((timezone) => (
              <Select.Option key={timezone?.id} value={timezone?.id}>
                {timezone?.time_zone}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="color_palette"
          label="Color Palette"
          rules={[
            {
              validator(_, value) {
                if (!value) return Promise.resolve();
                const errors = [];
                const hex = value.split(",");

                if (hex.length > 8) errors.push("Cannot contain more than 8 colors");

                for (const color of hex) {
                  if (!isHexCode(color)) errors.push(`${color} is an invalid hex color`);
                }

                if (errors.length > 0) return Promise.reject(errors);
                return Promise.resolve();
              }
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item name="font_family" label="Font Family">
          <Select
            mode="single"
            allowClear
            placeholder="Select font families"
            showSearch
            onSearch={handleFontSearch}
            filterOption={false}
            loading={fontLoading}
            options={fontOptions}
          />
        </Form.Item>

        <Submit handleClick={() => props.setDrawerOpen(false)} />
      </Form>
      <EditModal isOpen={isModalOpen && !newCompany} handleOk={handleOk} handleCancel={handleCancel} />
    </Drawer>
  );
};

export default EditCompanyDrawer;
