import { doGet, doPost, doPut, doDelete } from "Requests/requests";

/**
 * Fetches all audio templates with optional company filtering.
 * @param {string|null} selectedCompany 
 * @returns {Promise<Object>} 
 */
export const getAudioTemplates = async (selectedCompany) => {
  try {
    const params = {
      offset: 0,
      limit: 100,
      ...(selectedCompany && { company: selectedCompany })
    };
    return await doGet(`/sound-repository`, { params });
  } catch (e) {
    throw e;
  }
};

/**
 * Fetches a single audio template by its ID.
 * @param {string} templateId 
 * @returns {Promise<Object>} 
 */
export const getSingleAudioTemplate = async (templateId) => {
  try {
    return await doGet(`/sound-repository/${templateId}`, {
      params: { sound_repository_id: templateId }
    });
  } catch (e) {
    throw e;
  }
};

/**
 * Creates a new audio template.
 * @param {FormData} formData 
 * @returns {Promise<Object>} 
 */
export const createAudioTemplate = async (formData) => {
  try {
    return await doPost(`/sound-repository`, formData);
  } catch (e) {
    throw e;
  }
};

/**
 * Updates an existing audio template.
 * @param {Object} data 
 * @returns {Promise<Object>} 
 */
export const editAudioTemplate = async (data) => {
  try {
    return await doPut(`/sound-repository/edit`, data);
  } catch (e) {
    throw e;
  }
};

/**
 * Deletes an audio template by its ID.
 * @param {string} 
 * @returns {Promise<Object>} 
 */
export const deleteAudioTemplate = async (id) => {
  try {
    return await doDelete(`/sound-repository/${id}`);
  } catch (e) {
    throw e;
  }
};
/**
 * Fetches the list of companies.
 * @returns {Promise<Object[]>} 
 */
export const getCompanies = async () => {
  try {
    const response = await doGet("/company");
    return response.sort((a, b) =>
      a.company_name.toLowerCase().localeCompare(b.company_name.toLowerCase())
    );
  } catch (error) {
    throw new Error(error?.response?.data?.detail || "Failed to fetch companies.");
  }
};