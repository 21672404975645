import { doGet } from "Requests/requests";

export const getAdvertiserList = async (searchTerm = "") => {
  const endpoint = "/vistar-advertiser";

  try {
    const response = await doGet(endpoint, { params: { search_term: searchTerm } });
    return response;
  } catch (error) {
    console.error("Failed to fetch advisors", error);
    throw error;
  }
};
